import moment from 'moment';
import LocalizationManager from '../../utils/localization/localization';

export const aboutUsData = {
  id: 'about',
  darkMode: true,
  headline: LocalizationManager.t('aboutUsTitle'),
  description: LocalizationManager.t('aboutUsDescription', {
    years: moment([2019, 0, 29]).fromNow().replace('years ago', ''),
    expyears: moment([2015, 0, 29]).fromNow().replace('years ago', '')
  })
};

export const technologiesData = {
  id: 'technologies',
  darkMode: true,
  headline: LocalizationManager.t('technologiesTitle'),
  description: LocalizationManager.t('technologiesDescription')
};

export const homeObjThree = {
  id: 'interested',
  darkMode: false,
  headline: LocalizationManager.t('areYouInterestedTitle'),
  description: '',
  buttonLabel: 'Portafolio',
  buttonPath: '/solutions'
};
