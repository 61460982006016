import React from 'react';
import ScrollToTop from '../../common/components/ScrollToTop/ScrollToTop';
import ContactUs from './SigninComponents/ContactUs';

const ContactUsPath = () => {
  return (
    <>
      <ScrollToTop />
      <ContactUs />
    </>
  );
};

export default ContactUsPath;
