import styled from 'styled-components';
import Colors from '../../../utils/Colors';

export const MainContainer = styled.div<{ darkMode: boolean; height: number }>`
  color: #fff;
  background: ${({ darkMode }) => (!darkMode ? Colors.white : Colors.black)};
  height: ${({ height }) => `${height}px`};
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;
export const Container = styled.div`
  display: flex;
  z-index: 1;
  width: 100%auto;
  max-width: 1100px;
  padding: 50px 0;
`;
export const InfoRow = styled.div<{ imgStart: boolean }>`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1'` : `'col1'`)};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1'` : `'col1 col1'`)};
  }
`;

export const TextContainer = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.h1<{ darkMode: boolean }>`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600px;
  color: ${({ darkMode }) => (darkMode ? Colors.textLight : Colors.textDark)};
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
export const Description = styled.p<{ darkMode: boolean }>`
  max-width: 50%;
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 24px;
  text-align: center;
  color: ${({ darkMode }) => (!darkMode ? Colors.textDark : Colors.textLight)};
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
