import React from 'react';
import LocalizationManager from '../../../utils/localization/localization';

import {
  MainContainer,
  Title,
  CardContainer,
  Card,
  CardIcon,
  CardTitle,
  CardContent
} from './ServiceElements';

const Services = () => {
  return (
    <MainContainer id="services">
      <Title>{LocalizationManager.t('solutionsTitle')}</Title>
      <CardContainer>
        <Card>
          <CardIcon src={'/images/solution1.png'} />
          <CardTitle>{LocalizationManager.t('solutionsMobileTitle')}</CardTitle>
          <CardContent>{LocalizationManager.t('solutionsMobileDescription')}</CardContent>
        </Card>
        <Card>
          <CardIcon src={'/images/solution2.png'} />
          <CardTitle>{LocalizationManager.t('solutionsWebTitle')}</CardTitle>
          <CardContent>{LocalizationManager.t('solutionsWebTitle')}</CardContent>
        </Card>
        <Card>
          <CardIcon src={'/images/solution3.png'} />
          <CardTitle>{LocalizationManager.t('solutionsDesktopTitle')}</CardTitle>
          <CardContent>{LocalizationManager.t('solutionsDesktopDescription')}</CardContent>
        </Card>
      </CardContainer>
    </MainContainer>
  );
};

export default Services;
