const en = {
  brand: 'GDL eStore',
  aboutUs: 'About us',
  solutions: 'Solutions',
  technologies: 'Technologies',
  ourWork: 'Our Work',
  contactUs: 'Contact Us',
  mainTitle: 'We listen to your ideas, and we translate them into software.',
  mainDescription: 'We develop the solution you are looking for, based on your needs.',
  aboutUsTitle: 'About us',
  aboutUsDescription:
    'We are a company consolidated for {{years}} years, with staff with more than {{expyears}} years of experience in information technology.',
  technologiesTitle: 'Technologies',
  technologiesDescription:
    'We master the most avant-garde technologies used by the most important industries, we keep ourselves continuously updated by acquiring the best practices of the market.',
  areYouInterestedTitle: 'You are interested in our work',
  solutionsTitle: 'Solutions',
  solutionsMobileTitle: '',
  solutionsMobileDescription:
    'Provide a specific solution that adapts to the platforms used by your users.',
  solutionsWebTitle: 'Aplicaciones Web',
  solutionsWebDescription: 'It offers a solution compatible with any platform.',
  solutionsDesktopTitle: 'Desktop Applications.',
  solutionsDesktopDescription:
    'Provide a specific solution that adapts to the platforms used by your users.',
  socialMedia: 'Social Media',
  twitter: 'Twitter',
  instagram: 'Instagram',
  facebook: 'Facebook',
  portfolio: 'Portfolio',
  portfolioMobile: 'Apps para Moviles',
  portfolioWeb: 'Apps Web',
  portfolioDesktop: 'Apps para Escritorio',
  footerContactTitle: 'Contact',
  footerContactWhatsApp: 'WhatsApp',
  footerContactPhone: 'Phone',
  footerContactEmail: 'Email',
  reservedRights: 'All rights reserved.',
  leaveUsAMessage: 'Leave us a message',
  whatIsYourEmail: 'What is your email address?',
  whatIsYourMessage: 'What is your message?',
  send: 'Send to',
  invalidEmail: 'Invalid Email',
  invalidContent: 'Please, attach more information in your message'
};

const es = {
  brand: 'gdlestore',
  aboutUs: 'Acerca de nosotros',
  solutions: 'Soluciones',
  technologies: 'Tecnologias',
  ourWork: 'Nuestro trabajo',
  contactUs: 'Contactanos',
  mainTitle: 'Escuchamos tus ideas, y las plasmamos en software.',
  mainDescription: 'Desarrollamos la solucion que buscas, con base a tus necesidades.',
  aboutUsTitle: 'Acerca de nosotros',
  aboutUsDescription:
    'Somos una empresa consolidada desde hace {{years}} años, con personal con más de {{expyears}} años de experiencia en tecnologias de la informacion',
  technologiesTitle: 'Tecnologias',
  technologiesDescription:
    'Dominamos las tecnologias mas vanguardistas y utilizadas por las grandes industrias, nos mantenemos continuamente en actualizacion adquiriendo las mejores practicas del mercado',
  areYouInterestedTitle: 'Te interesa nuestro trabajo',
  solutionsTitle: 'Soluciones',
  solutionsMobileTitle: 'Aplicaciones Mobiles',
  solutionsMobileDescription:
    'Mantente a la vanguardia llegando a la palma de tu mano de tus usuarios.',
  solutionsWebTitle: 'Aplicaciones Web',
  solutionsWebDescription: 'Ofrece una solucion compatible con cualquier plataforma.',
  solutionsDesktopTitle: 'Aplicaciones de Escritorio.',
  solutionsDesktopDescription:
    'Otorga una solucion especifica que se adapte a las plataformas utilizadas por tus usuarios.',
  socialMedia: 'Redes Sociales',
  twitter: 'Twitter',
  instagram: 'Instagram',
  facebook: 'Facebook',
  portfolio: 'Portafolio',
  portfolioMobile: 'Apps para Moviles',
  portfolioWeb: 'Apps Web',
  portfolioDesktop: 'Apps para Escritorio',
  footerContactTitle: 'Contacto',
  footerContactWhatsApp: 'WhatsApp',
  footerContactPhone: 'Telefono',
  footerContactEmail: 'Correo Electronico',
  reservedRights: 'Todos los derechos reservados.',
  leaveUsAMessage: 'Dejanos un mensaje',
  whatIsYourEmail: 'Cual es tu correo electronico',
  whatIsYourMessage: 'Cual es tu mensaje?',
  send: 'Enviar',
  invalidEmail: 'Correo Invalido',
  invalidContent: 'Porfavor, ingrese mas informacion en su mensaje'
};

export { en, es };
