import React, { useState } from 'react';
import Footer from '../../common/components/Footer/Footer';
import Main from '../../common/components/Main';
import Info from '../../common/components/Info/Info';
import { aboutUsData, technologiesData } from './Data';
import Navbar from '../../common/components/Navbar/Navbar';
import Services from '../../common/components/Services/Services';
import Sidebar from '../../common/components/SideBar/SideBar';
import { useWindowSize } from '../../hooks/customHooks';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const size = useWindowSize();

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Main height={size.height ?? 0} />
      <Info {...aboutUsData} height={size.height ?? 0} />
      <Services />
      <Info {...technologiesData} height={size.height ?? 0} />
      <Footer />
    </>
  );
};

// <Info {...homeObjThree} height={size.height || 0}/>

export default Home;
