import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from '../../../utils/Colors';

export const Button = styled(Link)<{ big?: boolean; darkMode: boolean }>`
  border-radius: 50px;
  background: ${({ darkMode }) => (darkMode ? Colors.primary : Colors.primaryLight)};
  white-space: nowrap;
  padding: ${({ big }) => (big ?? false ? '14px  48px' : '12px 30px')};
  color: ${({ darkMode }) => (darkMode ? Colors.textLight : Colors.textDark)};
  font-size: ${({ big }) => (big ?? false ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ darkMode }) => (darkMode ? Colors.primaryLight : Colors.primary)};
  }
`;
