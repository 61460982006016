import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './features/home/Home';
import ContactUsPath from './features/contactUs/ContactUsPath';
import LocalizationManager from './utils/localization/localization';

function App() {
  React.useEffect(() => {
    if (navigator.languages !== undefined) {
      LocalizationManager.locale = navigator.languages[0].split('-')[0] ?? 'es';
    }
  });

  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contactUs" component={ContactUsPath} exact />
      </Switch>
    </Router>
  );
}

export default App;
