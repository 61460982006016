import React from 'react';
import { Button } from '../Button/ButtonElements';
import {
  MainContainer,
  Container,
  TextContainer,
  Title,
  Description,
  ButtonContainer
} from './InfoElements';

interface InfoProperties {
  id: string;
  headline: string;
  description: string;
  height: number;
  darkMode: boolean;
  buttonLabel?: string;
  buttonPath?: string;
}

const Info = ({
  id,
  headline,
  description,
  darkMode,
  height,
  buttonLabel,
  buttonPath
}: InfoProperties) => {
  return (
    <MainContainer darkMode={darkMode} id={id} height={height}>
      <Container>
        <TextContainer>
          <Title darkMode={darkMode}>{headline}</Title>
          <Description darkMode={darkMode}>{description}</Description>
          {buttonLabel !== undefined && buttonPath !== undefined && (
            <ButtonContainer>
              {
                <Button to={buttonPath ?? ''} darkMode={darkMode}>
                  {buttonLabel}
                </Button>
              }
            </ButtonContainer>
          )}
        </TextContainer>
      </Container>
    </MainContainer>
  );
};

export default Info;
