import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap
} from './SidebarElements';
import LocalizationManager from '../../../utils/localization/localization';

interface SidebarProperties {
  isOpen: boolean;
  toggle: () => void;
}

// <SidebarLink to="interested" onClick={toggle}>
//   {LocalizationManager.t('ourWork')}
// </SidebarLink>

const Sidebar = ({ isOpen, toggle }: SidebarProperties) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about" onClick={toggle}>
            {LocalizationManager.t('aboutUs')}
          </SidebarLink>
          <SidebarLink to="services" onClick={toggle}>
            {LocalizationManager.t('solutions')}
          </SidebarLink>
          <SidebarLink to="technologies" onClick={toggle}>
            {LocalizationManager.t('technologies')}
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/contactUs">{LocalizationManager.t('contactUs')}</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
