const Colors = {
  primary: '#5049F8',
  primaryDark: '#160EDC',
  primaryLight: '#A7A3FF',
  secondary: '#000',
  textLight: '#ECEFF1',
  textDark: '#263238',
  white: '#FEFEFE',
  black: '#263238'
};

export default Colors;
