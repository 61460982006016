import { I18n } from 'i18n-js';
import { en, es } from './labels';

export const LocalizationManager = new I18n();

LocalizationManager.enableFallback = true;
LocalizationManager.translations = { en, es };
LocalizationManager.locale = 'es';

export default LocalizationManager;
