import {
  FooterContainer,
  FooterWrap,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkContainer,
  FooterLinkTitle,
  FooterLink,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights
} from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';
import LocalizationManager from '../../../utils/localization/localization';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  // <FooterLinkItems>
  //   <FooterLinkTitle>{LocalizationManager.t('portafolio')}</FooterLinkTitle>
  //   <FooterLink to="/">{LocalizationManager.t('portafolioMobile')}</FooterLink>
  //   <FooterLink to="/">{LocalizationManager.t('portafolioWeb')}</FooterLink>
  //   <FooterLink to="/">{LocalizationManager.t('portafolioDesktop')}</FooterLink>
  // </FooterLinkItems>

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{LocalizationManager.t('socialMedia')}</FooterLinkTitle>
              <FooterLink to={{ pathname: 'https://www.twitter.com/gdlestore' }} target={'_blank'}>
                {LocalizationManager.t('twitter')}
              </FooterLink>
              <FooterLink
                to={{ pathname: 'https://www.instagram.com/gdlestore' }}
                target={'_blank'}>
                {LocalizationManager.t('instagram')}
              </FooterLink>
              <FooterLink to={{ pathname: 'https://www.facebook.com/gdlestore' }} target={'_blank'}>
                {LocalizationManager.t('facebook')}
              </FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{LocalizationManager.t('footerContactTitle')}</FooterLinkTitle>
              <FooterLink to={{ pathname: 'https://wa.me/3321814488' }} target={'_blank'}>
                {LocalizationManager.t('footerContactWhatsApp')}
              </FooterLink>
              <FooterLink to={{ pathname: 'tel:3321814488' }} target={'_blank'}>
                {LocalizationManager.t('footerContactPhone')}
              </FooterLink>
              <FooterLink to={{ pathname: 'mailto:info@gdlestore.com' }} target={'_blank'}>
                {LocalizationManager.t('footerContactEmail')}
              </FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinkContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              {LocalizationManager.t('brand')}
            </SocialLogo>
            <WebsiteRights>{`${LocalizationManager.t(
              'brand'
            )} © ${new Date().getFullYear()} ${LocalizationManager.t(
              'reservedRights'
            )}`}</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
