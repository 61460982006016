import React, { useState } from 'react';
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormInput,
  FormH1,
  FormLabel,
  FormButton,
  FormArea,
  LogoContainer,
  BrandLogo,
  ErrorMessage,
  Loader,
  LoaderContainer
} from './ContactUsElements';
import moment from 'moment';
import axios from 'axios';
import LocalizationManager from '../../../utils/localization/localization';
import jwt from 'jsonwebtoken';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [contentError, setContentError] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const secret =
    '63fabaf8c8abe47b8e994610751dd4c8c361f25e7328ae186a22b7d617f91b814504567b52827644793f3b1e61d718e44b12d16268b24306ad1eab85e30663a4';

  const getToken = async () =>
    await new Promise((resolve, reject) => {
      jwt.sign({}, secret, { expiresIn: '1h' }, (error?: Error, token?: string) => {
        if (token !== undefined) {
          resolve(token);
        } else if (error !== undefined) {
          reject(error);
        } else {
          reject(new Error('no token generation'));
        }
      });
    });

  const createSessionId = async () => {
    try {
      const sessionIdValue = (await getToken()) as string;
      localStorage.setItem('sessionId', sessionIdValue);
      return sessionIdValue;
    } catch (error) {
      return null;
    }
  };

  const verifyEmailData = async () => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp(
      // eslint-disable-next-line prefer-regex-literals
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
    );
    if (!regex.test(email)) {
      setEmailError(true);
      return;
    }
    if (message.length < 20) {
      setContentError(true);
      return;
    }
    setSending(true);
    let sessionId = localStorage.getItem('sessionId');
    if (sessionId === null) {
      sessionId = await createSessionId();
      if (sessionId !== null) {
        await sendEmail(sessionId);
      }
      return;
    }
    try {
      const tokenData = jwt.verify(sessionId ?? '', secret) as { exp: number };
      const tokenExp = tokenData.exp;
      if (tokenExp !== null) {
        const lastSent = moment(parseInt(`${tokenExp}000` ?? ''));
        lastSent.add(1, 'hour');
        if (moment().isAfter(lastSent)) {
          await sendEmail(sessionId);
        } else {
          setSending(true);
          setTimeout(() => {
            setSending(false);
            clearForm();
          }, 5000);
        }
      } else {
        await createSessionId();
        clearForm();
      }
    } catch (error) {
      await createSessionId();
      clearForm();
    }
  };

  const clearForm = () => {
    setEmail('');
    setMessage('');
    setSending(false);
  };

  const sendEmail = async (sessionId: string) => {
    try {
      const payload = { email, message, sessionId };
      await axios.post(
        // 'http://localhost:5001/gdlestore-6c1d2/us-central1/contact/sendMessage',
        'https://us-central1-gdlestore-6c1d2.cloudfunctions.net/contact/sendMessage',
        payload
      );
      clearForm();
    } catch (error) {
      clearForm();
    }
  };

  const setValueFactory =
    (setter: {
      (value: React.SetStateAction<string>): void;
      (value: React.SetStateAction<string>): void;
      (arg0: string): void;
    }) =>
    (event: { target: { value: string } }) => {
      setter(event.target.value);
      setEmailError(false);
      setContentError(false);
    };

  return (
    <>
      <Container>
        <FormWrap>
          <LogoContainer to="/">
            <BrandLogo src="/images/logo-white.svg" />
            <Icon>{LocalizationManager.t('brand')}</Icon>
          </LogoContainer>
          <FormContent>
            <Form action="#">
              <FormH1>{LocalizationManager.t('leaveUsAMessage')}</FormH1>
              <FormLabel htmlFor="for">{LocalizationManager.t('whatIsYourEmail')}</FormLabel>
              {emailError && <ErrorMessage>{LocalizationManager.t('invalidEmail')}</ErrorMessage>}
              <FormInput
                type="email"
                required
                onChange={setValueFactory(setEmail)}
                value={email}
                disabled={sending}
              />
              <FormLabel htmlFor="for">{LocalizationManager.t('whatIsYourMessage')}</FormLabel>
              {contentError && (
                <ErrorMessage>{LocalizationManager.t('invalidContent')}</ErrorMessage>
              )}
              <FormArea
                required
                onChange={setValueFactory(setMessage)}
                value={message}
                disabled={sending}
              />
              {!sending ? (
                <FormButton type="submit" onClick={verifyEmailData} disabled={sending}>
                  {LocalizationManager.t('send')}
                </FormButton>
              ) : (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              )}
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default ContactUs;
