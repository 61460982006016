import React, { useState } from 'react';
import { MainContainer, Container, Title, Description, ButtonContainer } from './HeroElements';
import { Button } from '../Button/ButtonElements';
import { LocalizationManager } from '../../../utils/localization/localization';

interface MainProperties {
  height: number;
}

const Main = ({ height }: MainProperties) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <MainContainer id="home" height={height}>
      <Container>
        <Title>{LocalizationManager.t('mainTitle')}</Title>
        <Description>{LocalizationManager.t('mainDescription')}</Description>
        <ButtonContainer>
          <Button to="/contactUs" onMouseEnter={onHover} onMouseLeave={onHover} darkMode={true}>
            {LocalizationManager.t('contactUs')}
          </Button>
        </ButtonContainer>
      </Container>
    </MainContainer>
  );
};

export default Main;
