import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
  NavBtn,
  NavBtnLink,
  BrandLogo,
  LogoContainer
} from './NavbarElements';
import { animateScroll as scroll } from 'react-scroll';
import { LocalizationManager } from '../../../utils/localization/localization';

interface NavbarProperties {
  toggle: () => void;
}

// <NavItem>
//   <NavLinks to="interested" smooth={true} duration={500} spy={true} offset={-80}>
//     {LocalizationManager.t('ourWork')}
//   </NavLinks>
// </NavItem>

const Navbar = ({ toggle }: NavbarProperties) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <LogoContainer to="/" onClick={toggleHome}>
              <BrandLogo src="/images/logo.svg" />
              <NavLogo>{LocalizationManager.t('brand')}</NavLogo>
            </LogoContainer>
            <MobileIcon onClick={toggle}>
              <FaBars style={{ fill: 'black' }} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to="about" smooth={true} duration={500} spy={true} offset={-80}>
                  {LocalizationManager.t('aboutUs')}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="services" smooth={true} duration={500} spy={true} offset={-80}>
                  {LocalizationManager.t('solutions')}
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="technologies" smooth={true} duration={500} spy={true} offset={-80}>
                  {LocalizationManager.t('technologies')}
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/contactUs">{LocalizationManager.t('contactUs')}</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
