import styled from 'styled-components';
import Colors from '../../../utils/Colors';

export const MainContainer = styled.div<{ height: number }>`
  background: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: ${({ height }) => `${height}px`};
  position: relative;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`;
export const Container = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.h1`
  color: ${Colors.textDark};
  font-size: 40px;
  text-align: center;

  @media screen and (max-wodth: 768px) {
    font-size: 40px;
  }
  @media screen and (max-wodth: 480px) {
    font-size: 32px;
  }
`;

export const Description = styled.p`
  margin-top: 24px;
  color: ${Colors.textDark};
  font-size: 18px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-wodth: 768px) {
    font-size: 24px;
  }
  @media screen and (max-wodth: 480px) {
    font-size: 18px;
  }
`;
export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
